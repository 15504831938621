define("ember-svg-jar/inlined/luna-trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.35 5.64h9.31v7.82a1.36 1.36 0 01-1.36 1.37H4.71a1.36 1.36 0 01-1.36-1.37zm9.09-3.72H10l-.1-.75H6.11L6 1.92H3.56a1 1 0 00-1 1v1.42h10.87V2.91a1 1 0 00-.99-.99z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});